export const checkIfHasVariants = (item) =>
  item.colors && item.colors.length>0 ||
  item.designs && item.designs.length>0 ||
  item.sizes && item.sizes.length>0 ||
  item.neutralOptions && item.neutralOptions.length>0;

export const checkIfVariantsMissing = (item, selectedVariants) => {
  if (checkIfHasVariants(item)) {
    return (
     
      (item.colors &&
        item.colors.length > 0 &&
        selectedVariants &&
        selectedVariants.colorSelected.value === "")||
        (item.sizes &&
          item.sizes.length > 0 &&
          selectedVariants &&
          selectedVariants.sizeSelected.value === "")
          ||
        (item.designs &&
          item.designs.length > 0 &&
          selectedVariants &&
          selectedVariants.designSelected.value === "")
          ||
        (item.neutralOptions &&
          item.neutralOptions.length > 0 &&
          selectedVariants &&
          selectedVariants.optionNeutralSelected.value === "")
    );
  }
  return checkIfHasVariants(item);
};
