import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  saveCheckoutLink: ['payload'],
  cleanCheckoutLink: []
});

export const InitPointCreator = Creators;

const initialState = {
  initPoint: '',
};

const saveCheckoutLink = (state = initialState, action) => {
  // eslint-disable-next-line camelcase
  const { init_point } = action.payload.result;
  return {
    ...state,
    initPoint: init_point,
  };
};

const cleanCheckoutLink = (state=initialState) => {
  return {
    ...state,
    initPoint: '',
  };
} 


export default createReducer(initialState, {
  [Types.SAVE_CHECKOUT_LINK]: saveCheckoutLink,
  [Types.CLEAN_CHECKOUT_LINK]: cleanCheckoutLink
});
