import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const uploadBannerTextService = serviceBase({
	request: text => axiosClient.patch('banner-text', text)
});

export const getBannerTextService = serviceBase({
	request: () => axiosClient.get('banner-text')
});

export default uploadBannerTextService;
