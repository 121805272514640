/* eslint-disable import/prefer-default-export */
import { BannerTextActionCreators } from '../reducers/bannerTextReducer';
import { getBannerTextService } from '../services/bannerText';

export const getBannerText = text => async dispatch => {
	dispatch(BannerTextActionCreators.requestBannerText());
	try {
		const response = await getBannerTextService();
		dispatch(
			BannerTextActionCreators.setBannerText({
				result: response
			})
		);
	} catch (e) {
	}
};